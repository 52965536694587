import React from 'react';
import sticklalogo from '../assets/stickla-logo.svg';

export default function HomePage() {
  return (
    <div className='stickla-logo'>
      <img src={sticklalogo} alt='Stickla' />
      <p>🤓 🤓 This is from dev-branch 🤓 🤓</p> 
    </div>
  )
}